<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <b-card-header>
        <div class="text-primary h2">
          <span class="d-none d-sm-inline"
            >{{ caseData.patient.first_name }}
            {{ caseData.patient.second_name }}
            {{ caseData.patient.first_surname }}
            {{ caseData.patient.second_surname }} Caso #
            {{ caseData.case_number }}</span
          >
        </div>
      </b-card-header>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="9"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
            <b-button
              variant="primary"
              @click="export2excel"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1">
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
            <b-button
              variant="primary"
              @click="export2pdf"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1">
              <span class="text-nowrap">{{ $t('list.exportPDF') }}</span>
            </b-button>
            <b-button
              v-if="case_id"
              :to="{
                name:
                  'apps-' +
                  $t(RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-create',
                params: { case_id: case_id },
              }"
              variant="primary">
              <span class="text-nowrap">{{
                $t(RESOURCES_ELEMENT_NAME + '.object.add')
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
        <!-- column: name -->
        <template #cell(name)="data">
          <b-badge
            pill
            class="text-capitalize">
            {{ data.item.name }}
          </b-badge>
        </template>
        <!-- Column: key -->
        <template #cell(registry_time)="data">
          {{ showDateTime(data.item.registry_time) }}
        </template>
        <!-- Column: observations -->
        <template #cell(observations)="data">
          <div v-if="data.value.length > 350">
            {{ data.value.substring(0, 350) }}...
            <router-link :to="getLink(data.item)">ver más</router-link>
          </div>
          <div v-else>
            {{ data.value }}
          </div>
        </template>
        <!-- Column: Status -->
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-col>
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              id="view-button"
              :to="{
                name:
                  'apps-' +
                  $t(RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-view',
                params: { id: data.item.id },
              }">
              <feather-icon icon="FileTextIcon" />
            </b-button>
            <!-- ? VIEW -->
            <b-tooltip
              target="view.button"
              :title="$t('actions.detail')"
              triggers="hover"
              :noninteractive="true"
              placement="topleft">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Back -->
    <b-row class="d-flex justify-content-end pt-2">
      <b-button
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="router().go(-1)">
        {{ $t('actions.back') }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardHeader,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Filters from './filters.vue'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '../storeModule'
import * as XLSX from 'xlsx'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  components: {
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      case_id: undefined,
      origin: undefined,
      tableColumns: [],
      caseData: {},
    }
  },
  mounted() {
    this.RESOURCES_ELEMENT_NAME = 'logbook'
    this.ELEMENT_APP_STORE_MODULE_NAME =
      'app-lb-li-' + this.RESOURCES_ELEMENT_NAME
    this.tableColumns = [
      {
        key: 'registry_time',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.registry_time'),
        sortable: true,
      },
      {
        key: 'user_value',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.user'),
        sortable: true,
      },
      {
        key: 'management_medium_value',
        label: this.$t(
          this.RESOURCES_ELEMENT_NAME + '.object.management_medium'
        ),
        sortable: true,
      },
      {
        key: 'observations',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.observations'),
        sortable: true,
        thStyle: 'width: 900px;',
      },
      {
        key: 'actions',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.actions'),
        thStyle: 'width: 150px;',
      },
    ]
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )
    this.case_id = router.currentRoute.params.case_id
    this.origin = router.currentRoute.params.origin
    this.fetchElements()
    this.fetchCase()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  watch: {
    perPage: function (val) {
      this.fetchElements()
    },
    currentPage: function (val) {
      this.fetchElements()
    },
    query: function (val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  methods: {
    router() {
      return router
    },
    showDateTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    filterJson(jsonData) {
      var filterList = this.tableColumns.map(function (a) {
        return a.key
      })
      filterList.pop()
      return jsonData.map((v) =>
        filterList.map((j) => {
          return v[j]
        })
      )
    },
    getHeader() {
      const res = {}
      for (var i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {},
    async export2excel() {
      const filename =
        'Bitácora_#' +
        this.caseData.case_number +
        '_' +
        this.caseData.patient.identity
      var result = await store.dispatch(
        this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_logbooks',
        {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: this.query,
          },
          case_id: this.case_id,
        }
      )

      result.data.items.unshift(this.getHeader())
      let list = this.filterJson(result.data.items)
      let data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    async export2pdf() {
      const filename =
        'Bitácora_#' +
        this.caseData.case_number +
        '_' +
        this.caseData.patient.identity
      var result = await store.dispatch(
        this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_logbooks',
        {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: this.query,
          },
          case_id: this.case_id,
        }
      )

      var columns = [
        {
          title: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.registry_time'),
          dataKey: 'registry_time',
        },
        {
          title: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.user'),
          dataKey: 'user_value',
        },
        {
          title: this.$t(
            this.RESOURCES_ELEMENT_NAME + '.object.management_medium'
          ),
          dataKey: 'management_medium_value',
        },
        {
          title: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.observations'),
          dataKey: 'observations',
        },
      ]

      var doc = new jsPDF('p', 'pt')
      doc.text('Reporte Bitácora', 30, 20)
      doc.autoTable(columns, result.data.items)
      doc.save(filename + '.pdf')
    },
    fetchElements() {
      store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_logbooks', {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
          },
          case_id: this.case_id,
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
        })
        .catch(() => {})
    },
    fetchCase() {
      store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetchCase', {
          id: this.case_id,
        })
        .then((response) => {
          this.caseData = response.data
        })
        .catch(() => {})
    },
    getLink(item) {
      return {
        name:
          'apps-' +
          this.$t(this.RESOURCES_ELEMENT_NAME + '.module_name') +
          '-view',
        params: {
          id: item.id,
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
