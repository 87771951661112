import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch_logbooks(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/logbook/logbook/case/${params.case_id}`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_logbook(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/logbook/logbook/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/logbook/logbook`, elementData.data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/' + type, { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 2 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, {parent}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 3, parent: parent } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCase(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
  
}
