var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.caseData.patient.first_name)+" "+_vm._s(_vm.caseData.patient.second_name)+" "+_vm._s(_vm.caseData.patient.first_surname)+" "+_vm._s(_vm.caseData.patient.second_surname)+" Caso # "+_vm._s(_vm.caseData.case_number))])])]),_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('table.search.show')))]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v(_vm._s(_vm.$t('table.search.entries')))])],1),_c('b-col',{staticClass:"d-flex justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"9"}},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":_vm.$t('table.search.search')},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":_vm.export2excel}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('list.export')))])]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":_vm.export2pdf}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('list.exportPDF')))])]),(_vm.case_id)?_c('b-button',{attrs:{"to":{
              name:
                'apps-' +
                _vm.$t(_vm.RESOURCES_ELEMENT_NAME + '.module_name') +
                '-create',
              params: { case_id: _vm.case_id },
            },"variant":"primary"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t(_vm.RESOURCES_ELEMENT_NAME + '.object.add')))])]):_vm._e()],1)],1)],1),_c('b-table',{ref:"refElementsListTable",staticClass:"position-relative",attrs:{"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t(_vm.RESOURCES_ELEMENT_NAME + '.search.not_found')},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":""}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"cell(registry_time)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.showDateTime(data.item.registry_time))+" ")]}},{key:"cell(observations)",fn:function(data){return [(data.value.length > 350)?_c('div',[_vm._v(" "+_vm._s(data.value.substring(0, 350))+"... "),_c('router-link',{attrs:{"to":_vm.getLink(data.item)}},[_vm._v("ver más")])],1):_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('b-col',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","id":"view-button","to":{
              name:
                'apps-' +
                _vm.$t(_vm.RESOURCES_ELEMENT_NAME + '.module_name') +
                '-view',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('b-tooltip',{attrs:{"target":"view.button","title":_vm.$t('actions.detail'),"triggers":"hover","noninteractive":true,"placement":"topleft"}})],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"per-page":_vm.perPage,"total-rows":_vm.total,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('b-row',{staticClass:"d-flex justify-content-end pt-2"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",on:{"click":function($event){_vm.router().go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }